import { DATA_TYPES, DataDefModel } from '@lib-resource/data-def.model';
import { RfiFieldDataModel, RfiFieldModel } from '@common/models/rfi-template.model';

export const rfiResponseDataDef: DataDefModel = new DataDefModel({
  label: '',
  key: 'rfiFieldData',
  type: DATA_TYPES.formGroupArray,
  definitions: [
    new DataDefModel({
      label: '',
      type: DATA_TYPES.text,
      key: 'value'
    })
  ]
});

export class RfiFieldUIDataModel extends RfiFieldDataModel {
  value?: any;
}
