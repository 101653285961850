import * as memoizee from 'memoizee';

// Allows devs to use functions in templates w/o them
// been called every-time there's change detection.
// Note: memoizee cache is NOT tied to the lifecycle of
// the component. So, if the same function is called with
// the same arguments (even after destroying/recreating the component)
// the cached value will be used.
export function Memoize() {
  return function (target, key, descriptor) {
    const oldFunction = descriptor.value;
    const newFunction = memoizee(oldFunction);
    descriptor.value = function () {
      return newFunction.apply(this, arguments);
    };
  };
}
