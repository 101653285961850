import { FormFieldType } from '@common/models/quote-type.model';

export class FormFieldDefinitionModel {
  id?: number;
  name?: string;
  type?: FormFieldType;
  groupName?: string;
  orderIndex?: number;
  inputSize?: number;
  maxInputSize?: number;
  minInputSize?: number;
  required?: boolean;
  defaultValues?: string[];
  selectOptions?: string[];
  dollarsOnly?: boolean;
  collectComments?: boolean;
  defaultValueToggle?: boolean;
  defaultValueText?: string;

  constructor(
    id: number,
    name: string,
    type: FormFieldType,
    groupName: string,
    inputSize: number,
    maxInputSize: number,
    minInputSize: number,
    required: boolean,
    defaultValues: string[],
    selectOptions: string[],
    dollarsOnly: boolean
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.groupName = groupName;
    this.inputSize = inputSize;
    this.maxInputSize = maxInputSize;
    this.minInputSize = minInputSize;
    this.required = required;
    this.defaultValues = defaultValues;
    this.selectOptions = selectOptions;
    this.dollarsOnly = dollarsOnly;
  }
}
