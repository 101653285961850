export const environment = {
  production: true,
  recaptchaSiteKey: '6Le87YQUAAAAAJGnmiJwPSwBWo-6mgB0tUbAv1BD',
  apiPrefix: '',
  hmr: false,
  idleConsecutiveSeconds: 2400, // 40 minutes, the amount of time a user is idle before getting a countdown
  idleTimeoutCountdownSeconds: 60, // the number of seconds in the warning timer before a user is logged out
  apryseLicenseKey:
    'Ringmaster Technologies  Inc :PWS:Ringmaster Web::B+2:0E3A859987166CAFDD44DD5EB88FE3E9C0DBDE77830074D7AE878EA67ED826BD',
  apryseDemoKey: 'demo:mslavens@ringmastertech.com:7e0717dc02000000009516a6d96595a8d2ccb3699d059d05d967caac41'
};
