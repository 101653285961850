import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BaseFormComponent } from '@form-lib/forms/base-form/base-form.component';
import { RfiFieldUIDataModel, rfiResponseDataDef } from '@common/components/rfi-response/rfi-response.definitions';
import { RfiService } from '@common/services/rfi.service';
import { DataDefModel } from '@lib-resource/data-def.model';
import { FormArray } from '@angular/forms';
import { RfiFieldDataModel, RfiFieldModel } from '@common/models/rfi-template.model';
import { FormFieldType } from '@common/models/quote-type.model';
import { FormFieldDefinitionModel } from '@common/models/form-field-definition.model';
import {
  buildFieldDefinition,
  showMultiCurrency,
  showMultiNumber,
  showMultiPercent,
  showMultiSelect,
  showSectionHeader,
  showSingleCurrency,
  showSingleNumber,
  showSinglePercent,
  showSingleSelect,
  showText,
  showTextArea,
  showToggle
} from '../form-builder/form-field-data.utils';

type ControlDef = {
  control: any;
  formDef: FormFieldDefinitionModel;
};
type AccordionSection = {
  sectionControl: ControlDef;
  childControls: ControlDef[];
};

@Component({
  selector: 'app-rfi-response',
  templateUrl: './rfi-response.component.html',
  styleUrls: ['./rfi-response.component.scss']
})
export class RfiResponseComponent implements OnChanges {
  @Input() preview: boolean = false;
  @Input() rfiFormFields: RfiFieldModel[];
  @Input() rfiFieldData: RfiFieldDataModel[];
  @Input() readOnly: boolean;
  @Input() openAccordions: boolean;
  @Output() update = new EventEmitter<{ rfiFieldData: RfiFieldDataModel[]; valid: boolean }>();

  @ViewChild(BaseFormComponent) rfiResponseForm: BaseFormComponent;

  localData: {
    rfiFieldData: RfiFieldUIDataModel[];
  };

  dataDefs: DataDefModel[] = [rfiResponseDataDef];

  constructor(private rfiService: RfiService) {}

  buildFieldDefinition(formGroup: any, formDef: FormFieldDefinitionModel): DataDefModel {
    return buildFieldDefinition(formGroup, formDef);
  }

  showToggle(formDef: FormFieldDefinitionModel): boolean {
    return showToggle(formDef);
  }

  showText(formDef: FormFieldDefinitionModel): boolean {
    return showText(formDef);
  }

  showTextArea(formDef: FormFieldDefinitionModel): boolean {
    return showTextArea(formDef);
  }

  showSectionHeader(formDef: FormFieldDefinitionModel): boolean {
    return showSectionHeader(formDef);
  }

  showSingleCurrency(formDef: FormFieldDefinitionModel): boolean {
    return showSingleCurrency(formDef);
  }

  showMultiCurrency(formDef: FormFieldDefinitionModel): boolean {
    return showMultiCurrency(formDef);
  }

  showSingleNumber(formDef: FormFieldDefinitionModel): boolean {
    return showSingleNumber(formDef);
  }

  showMultiNumber(formDef: FormFieldDefinitionModel): boolean {
    return showMultiNumber(formDef);
  }

  showSinglePercent(formDef: FormFieldDefinitionModel): boolean {
    return showSinglePercent(formDef);
  }

  showMultiPercent(formDef: FormFieldDefinitionModel): boolean {
    return showMultiPercent(formDef);
  }

  showSingleSelect(formDef: FormFieldDefinitionModel): boolean {
    return showSingleSelect(formDef);
  }

  showMultiSelect(formDef: FormFieldDefinitionModel): boolean {
    return showMultiSelect(formDef);
  }

  accordionSections: Array<AccordionSection> = undefined;

  getAccordionSections(): Array<AccordionSection> {
    if (this.accordionSections) {
      return this.accordionSections;
    }

    const formArray = this.rfiResponseForm.form.controls[rfiResponseDataDef.formKey];
    const controls = (<FormArray>formArray).controls;
    let sectionDefs: AccordionSection[] = [];

    if (controls?.length) {
      let currentSection: AccordionSection = {
        sectionControl: undefined,
        childControls: []
      };
      if (this.localData.rfiFieldData[0].rfiField.formFieldDefinition.type === FormFieldType.SECTION_HEADER) {
        currentSection.sectionControl = {
          control: controls[0],
          formDef: this.localData.rfiFieldData[0].rfiField.formFieldDefinition
        };
      }

      let index = currentSection.sectionControl ? 1 : 0;

      for (index; index < controls.length; index++) {
        if (this.localData.rfiFieldData[index].rfiField.formFieldDefinition.type === FormFieldType.SECTION_HEADER) {
          sectionDefs.push(currentSection);
          currentSection = {
            sectionControl: {
              control: controls[index],
              formDef: this.localData.rfiFieldData[index].rfiField.formFieldDefinition
            },
            childControls: []
          };
        } else {
          currentSection.childControls.push({
            control: controls[index],
            formDef: this.localData.rfiFieldData[index].rfiField.formFieldDefinition
          });
        }
      }

      if (!!currentSection.sectionControl && currentSection.childControls.length) {
        sectionDefs.push(currentSection);
      }
    }

    this.accordionSections = sectionDefs;
    return this.accordionSections;
  }

  /**
   * This method handles identifying when there are existing answers and mapping the existing answers along with the provided list of questions into
   * a UI representation of the answer, also known as data.
   *
   * When rfiFormFields are provided, and no accompanying data, it is assumed this is either a preview with no intention of saving, or a brand-new response.
   * When rfiFieldData are provided, they need to be interwoven with the rfiFormField list because new questions could have been added OR removed since the
   * answer was saved.
   * @param rfiFormFields
   * @param rfiFieldData
   */
  ngOnChanges({ rfiFormFields, rfiFieldData }: SimpleChanges) {
    // When in preview mode, just take the questions and map them to dummy answers
    if (!this.localData && rfiFormFields?.currentValue && this.preview) {
      this.localData = {
        rfiFieldData: this.rfiService.buildPreviewUIData(rfiFormFields.currentValue)
      };
    }

    if (!this.localData && rfiFormFields?.currentValue && rfiFieldData?.currentValue) {
      this.localData = {
        rfiFieldData: this.rfiService.consolidateFieldsWithData(rfiFormFields.currentValue, rfiFieldData.currentValue)
      };
    }

    setTimeout(() => {
      if (this.rfiResponseForm?.form && this.rfiResponseForm.form.untouched) {
        this.rfiResponseForm.form.markAllAsTouched();
      }
    });
  }

  formChanges(formData: any) {
    for (let i = 0; i < formData.rfiFieldData?.length; i++) {
      this.localData.rfiFieldData[i].value = formData.rfiFieldData[i].value;
    }

    this.update.emit({ rfiFieldData: this.localData.rfiFieldData, valid: !this.rfiResponseForm.invalid });
  }
}
