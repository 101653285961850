import { AccordionComponent } from '@accordion-lib/accordion/accordion.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { AccordionItemComponent } from './accordion-item/accordion-item.component';

const exportItems = [AccordionItemComponent, AccordionComponent];

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [...exportItems],
  exports: [...exportItems]
})
export class AccordionLibModule {}
