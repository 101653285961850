<div class="accordion-item mb-1 mat-elevation-z2 dense-layout">
  <div
    class="accordion-item__header p-1 bg-extra-light-gray"
    [class.item-open]="isOpen"
    [class.item-incumbent]="incumbent"
    [ngStyle]="{ minHeight: minHeight }"
  >
    <label *ngIf="incumbent" class="incumbent-label">Incumbent</label>

    <div class="accordion-label">
      <ng-content select=".accordion-item-label"></ng-content>
    </div>

    <ng-content select=".accordion-item-header"></ng-content>
    <button type="button" [disabled]="disabled" class="toggler" mat-icon-button (click)="onClickHeader()">
      <fa-icon [icon]="chevronIcon" [rotate]="isOpen && !disabled ? 180 : null"></fa-icon>
    </button>
  </div>
  <div class="accordion-item__content" [class.is-closed]="isClosed" [@openCloseAnimation]="isOpen">
    <ng-container *ngIf="isOpen && !disabled">
      <ng-content select=".accordion-item-content"></ng-content>
    </ng-container>
  </div>
</div>
