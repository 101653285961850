import { TagModel } from '@configuration/admin/tag-list/tag.model';
import { DATA_TYPES, DataDefModel, DataDefOption } from '@lib-resource/data-def.model';
import { OrgMinModel, OrgModel } from '@main/org/models/org.model';
import { PolicyCoverageType } from '@smart/component/policy/model/policy.model';
import { QuoteTypeFieldDataModel } from '@common/models/quote-type.model';
import { nullOrUndefinedToZero } from '@app/tools/number';
import { OcrModel } from '@form-lib/ocr-overlay/ocr.model';
import { get } from 'lodash-es';
import { FundingTypeModel } from '@common/models/funding-type.model';
import { QuoteRequestModel } from './quote-request.model';

export enum QuoteResponseStatus {
  NEW = 'NEW',
  UNDERWRITING = 'UNDERWRITING',
  SUBMITTED = 'SUBMITTED',
  SHORTLIST = 'SHORTLIST',
  WON = 'WON',
  LOST = 'LOST',
  DECLINED = 'DECLINED',
  CLOSED = 'CLOSED',
  BOUND = 'BOUND'
}

export enum ConfirmationDialogType {
  ACCEPT_INVITATION = 'ACCEPT_INVITATION',
  DTQ = 'DTQ',
  LOST = 'LOST',
  SUBMIT_QUOTE = 'SUBMIT_QUOTE',
  SUBMIT_CONTRACT = 'SUBMIT_CONTRACT',
  SUBMIT_CONTRACT_EXISTING_FILE = 'SUBMIT_CONTRACT_EXISTING_FILE'
}

export enum ConfirmationDialogAction {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY'
}

export enum LaserRelationship {
  EE = 'EE',
  SP = 'SP',
  CH = 'CH'
}

export enum FeeRecurrence {
  PEPM = 'PEPM',
  PE = 'PE',
  MONTHLY = 'MONTHLY',
  ONCE = 'ONCE'
}

/**
 * When Updating the Proposal Status, a modal window is presented to the user displaying the progression of the status.
 * This const describes the messages that are displayed in the update proposal status modal, and the actions that are
 * supported for each status type.
 */
export const QuoteResponseUpdateStatusModalData = {
  NEW: {
    label: 'New',
    messageHeader: 'You may choose to accept the request or decline to quote.',
    primaryButton: 'Accept',
    secondaryButton: 'Decline',
    nextAcceptState: 'UNDERWRITING',
    nextCancelState: 'DECLINED'
  },
  UNDERWRITING: {
    label: 'Underwriting',
    messageHeader: 'You may choose to submit your proposal or decline to quote.',
    primaryButton: 'Submit Quote',
    secondaryButton: 'Decline',
    nextAcceptState: 'SUBMITTED',
    nextCancelState: 'DECLINED',
    confirmationDialogType: ConfirmationDialogType.ACCEPT_INVITATION
  },
  SUBMITTED: {
    label: 'Submitted',
    messageHeader: 'The proposal has been submitted and is under review.',
    secondaryButton: 'Decline',
    nextCancelState: 'DECLINED',
    confirmationDialogType: ConfirmationDialogType.SUBMIT_QUOTE
  },
  SHORTLIST: {
    label: 'Shortlist',
    messageHeader: 'The proposal has been marked as Shortlist.',
    secondaryButton: 'Decline',
    nextCancelState: 'DECLINED'
  },
  WON: {
    label: 'Won',
    messageHeader: 'Your proposal has been selected as the winning quote.',
    secondaryButton: 'Decline',
    nextCancelState: 'DECLINED'
  },
  LOST: {
    label: 'Lost',
    messageHeader: 'Lost the RFP bid.'
  },
  DECLINED: {
    label: 'Declined',
    messageHeader: 'Declined to Quote.',
    confirmationDialogType: ConfirmationDialogType.DTQ
  },
  CLOSED: {
    label: 'Closed',
    messageHeader: 'The RFP has been closed.'
  },
  BOUND: {
    label: 'Bound',
    messageHeader: 'The proposal has been marked as bound.',
    confirmationDialogType: ConfirmationDialogType.SUBMIT_CONTRACT
  }
};

export const quoteResponseStatusOptions: DataDefOption[] = [
  {
    label: 'New',
    value: QuoteResponseStatus.NEW
  },
  {
    label: 'Underwriting',
    value: QuoteResponseStatus.UNDERWRITING
  },
  {
    label: 'Submitted',
    value: QuoteResponseStatus.SUBMITTED
  },
  {
    label: 'Shortlist',
    value: QuoteResponseStatus.SHORTLIST
  },
  {
    label: 'Lost',
    value: QuoteResponseStatus.LOST
  },
  {
    label: 'Declined',
    value: QuoteResponseStatus.DECLINED
  },
  {
    label: 'Won',
    value: QuoteResponseStatus.WON
  },
  {
    label: 'Bound',
    value: QuoteResponseStatus.BOUND
  },
  {
    label: 'Closed',
    value: QuoteResponseStatus.CLOSED
  }
];

export const quoteOptionStatusOptions: DataDefOption[] = [
  {
    label: 'Submitted',
    value: QuoteResponseStatus.NEW
  },
  {
    label: 'Shortlist',
    value: QuoteResponseStatus.SHORTLIST
  },
  {
    label: 'Lost',
    value: QuoteResponseStatus.LOST
  },
  {
    label: 'Declined',
    value: QuoteResponseStatus.DECLINED
  },
  {
    label: 'Won',
    value: QuoteResponseStatus.WON
  },
  {
    label: 'Bound',
    value: QuoteResponseStatus.BOUND
  },
  {
    label: 'Closed',
    value: QuoteResponseStatus.CLOSED
  }
];

export const quoteResponseEditableStatuses = {
  NEW: QuoteResponseStatus.NEW,
  UNDERWRITING: QuoteResponseStatus.UNDERWRITING,
  SUBMITTED: QuoteResponseStatus.SUBMITTED,
  SHORTLIST: QuoteResponseStatus.SHORTLIST,
  WON: QuoteResponseStatus.DECLINED
};

export enum QuoteResponseReason {
  ALTERNATIVE_MANAGEMENT_STRATEGIES = 'ALTERNATIVE_MANAGEMENT_STRATEGIES',
  CANCELED_DID_NOT_PURSUE = 'CANCELED_DID_NOT_PURSUE',
  CARRIER_HISTORY = 'CARRIER_HISTORY',
  COULD_NOT_FIND_COVERAGE = 'COULD_NOT_FIND_COVERAGE',
  DISRUPTION_CONCERNS = 'DISRUPTION_CONCERNS',
  DUPLICATE = 'DUPLICATE',
  GROUP_REMAINED_FULLY_INSURED = 'GROUP_REMAINED_FULLY_INSURED',
  GROUP_TERMINATED = 'GROUP_TERMINATED',
  INCOMPLETE_RFP_DATA = 'INCOMPLETE_RFP_DATA',
  INELIGIBLE_INDUSTRY = 'INELIGIBLE_INDUSTRY',
  LASER_CARVE_OUTS = 'LASER_CARVE_OUTS',
  LOST_STOP_LOSS_KEPT_ADMIN = 'LOST_STOP_LOSS_KEPT_ADMIN',
  MISSED_DEADLINE = 'MISSED_DEADLINE',
  PLACED_OUTSIDE_QUOTE_LINQ = 'PLACED_OUTSIDE_QUOTE_LINQ',
  POOR_LOSS_HISTORY = 'POOR_LOSS_HISTORY',
  POOR_PARTICIPATION_PERCENT = 'POOR_PARTICIPATION_PERCENT',
  PRICING_NOT_COMPETITIVE = 'PRICING_NOT_COMPETITIVE',
  RENEWED_SAME_BROKER = 'RENEWED_SAME_BROKER',
  RENEWED_SAME_CARRIER = 'RENEWED_SAME_CARRIER',
  RENEWED_SAME_TPA = 'RENEWED_SAME_TPA',
  UNABLE_TO_FIRM_PROPOSAL = 'UNABLE_TO_FIRM_PROPOSAL',
  UNABLE_TO_MATCH_PROGRAMS = 'UNABLE_TO_MATCH_PROGRAMS',
  UNEVALUATED_PROVIDER_NETWORK = 'UNEVALUATED_PROVIDER_NETWORK',
  UNSATISFIED_CARRIER_REQUIREMENTS = 'UNSATISFIED_CARRIER_REQUIREMENTS',
  UNSATISFIED_CONTRACT_TYPE = 'UNSATISFIED_CONTRACT_TYPE',
  UNSATISFIED_DEDUCTIBLE = 'UNSATISFIED_DEDUCTIBLE',
  UNSATISFIED_OTHER_REQUESTED_TERMS = 'UNSATISFIED_OTHER_REQUESTED_TERMS',
  UNSATISFIED_STATE_GUIDELINES = 'UNSATISFIED_STATE_GUIDELINES',
  RENEWED_WITH_INCUMBENT = 'RENEWED_WITH_INCUMBENT'
}

export enum QuoteResponseType {
  ILLUSTRATIVE = 'ILLUSTRATIVE',
  FINAL = 'FINAL',
  FIRM = 'FIRM'
}

export enum QuoteResponseLaserConfirmationType {
  CONFIRMED_LASERS_INCLUDED = 'CONFIRMED_LASERS_INCLUDED',
  CONFIRMED_LASERS_NOT_INCLUDED = 'CONFIRMED_LASERS_NOT_INCLUDED',
  UNCONFIRMED = 'UNCONFIRMED'
}

export enum QuoteResponseOptionTermType {
  MONTH_3 = 'MONTH_3',
  MONTH_6 = 'MONTH_6',
  NONE = 'NONE'
}

export enum QuoteResponseOptionPayScheduleType {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY'
}

export enum RateType {
  TS01 = 'TS01',
  TS02 = 'TS02',
  TS03 = 'TS03',
  TS04 = 'TS04'
}

export const RATE_TYPE_OPTIONS: DataDefOption[] = [
  {
    label: 'Composite',
    value: RateType.TS01
  },
  {
    label: '2-Tier',
    value: RateType.TS02
  },
  {
    label: '3-Tier',
    value: RateType.TS03
  },
  {
    label: '4-Tier',
    value: RateType.TS04
  }
];

export class QuoteResponseOption {
  id?: number;
  quoteResponseId?: number;
  createdDate?: Date;
  lastModifiedDate?: Date;
  stateChangeDate?: Date;
  status?: QuoteResponseStatus = QuoteResponseStatus.NEW;
  specAdvancedReimbursement?: boolean;
  specTermLiabilityCoverage?: boolean;
  specTerminalLiabilityRate?: number;
  specCoverage?: PolicyCoverageType[];
  specContractType?: string;
  specDeductible?: number;
  specAnnualMaximumUnlimited?: boolean;
  specAnnualMaximum?: number;
  specLifetimeMaximumUnlimited?: boolean;
  specLifetimeMaximum?: number;
  specFamilyDeductible?: boolean;
  empOnlyRate?: number;
  empSpouseRate?: number;
  empChildRate?: number;
  empDepRate?: number;
  familyRate?: number;
  family?: number;
  compositeRate?: number;
  premiumAmount?: number;
  specRetireesCovered?: boolean;
  aggCoverage?: PolicyCoverageType[];
  aggContractType?: string;
  aggLossLimitPerIndividual?: number;
  aggMaximum?: number;
  aggCompositeRate?: number;
  monthlyAccRate?: number;
  terminalLiabilityRate?: number;
  aggEstAnnualPremium?: number;
  aggCommission?: number;
  aggSpec?: number;
  aggDeductible?: number;
  minAggDeductible?: number;
  runInRunOutLimit?: number;
  riskCorridor?: number;
  empOnlyMonthlyClaimFactor?: number;
  empSpouseMonthlyClaimFactor?: number;
  empChildMonthlyClaimFactor?: number;
  empDepMonthlyClaimFactor?: number;
  familyMonthlyClaimFactor?: number;
  compositeMonthlyClaimFactor?: number;
  laserLiability?: number;
  orderIndex?: number;
  specRunInLimit?: number;
  specPremLoadVsLaser?: boolean;
  specPremLoadVsLaserAmount?: number;
  specTermLiabilityTerm?: QuoteResponseOptionTermType;
  specReimbPercent?: number;
  specDomReimbPercent?: number;
  specMonthlyPremiumAmount?: number;
  specCommission?: number;
  specNoNewLaserOption?: boolean;
  specGaplessOption?: boolean;
  specRenewalRateCap?: number;
  specRenewalRateCapOption?: boolean;
  specTransplantExclusion?: boolean;
  specPlanMirroringOption?: boolean;
  specActiveAtWorkWaived?: boolean;
  aggReimbPercent?: number;
  aggEmpOnlyRate?: number;
  aggEmpSpouseRate?: number;
  aggEmpChildRate?: number;
  aggEmpDepRate?: number;
  aggFamilyRate?: number;
  aggAccommodation?: boolean;
  aggTermLiabilityTerm?: QuoteResponseOptionTermType;
  aggTermLiabilityCoverage?: boolean;
  aggMonthlyPremium?: number;
  aggPaymentSchedule?: QuoteResponseOptionPayScheduleType;
  annualFixedCosts?: number;
  maxAnnualLiability?: number;
  lasers?: LaserModel[];
  specRateSchedule?: RateType;
  aggFactorSchedule?: RateType;
  aggRateSchedule?: RateType;
  quoteTypeFieldData?: QuoteTypeFieldDataModel[];
  fundingType?: FundingTypeModel;
  policyAdministrator?: string;
  policyPBM?: string;
  policyNetwork?: string;
  policyTransplantVendor?: string;
  policyBenefitPlans?: string[];
  additionalFees?: AdditionalFeeModel[];
}

export class LaserModel {
  id?: number;
  quoteResponseOptionId?: number;
  name?: string;
  relationship?: LaserRelationship;
  specDeductible?: number;
  contractBasis?: string;
  maxReimbursement?: number;
  conditional?: boolean;
  condition?: string;
  excluded?: boolean;
  constructor(excluded: boolean = false) {
    this.excluded = excluded;
  }
}

export class AdditionalFeeModel {
  id?: number;
  quoteResponseOptionId?: number;
  name?: string;
  type?: string;
  recurrence?: FeeRecurrence;
  amount?: number;
}

export class QuoteResponseModel {
  id?: number;
  status?: QuoteResponseStatus;
  quoteRequest?: QuoteRequestModel;
  org?: OrgModel;
  createdDate?: string;
  lastModifiedDate?: string;
  drcCount?: number;
  stateChangeDate?: string;
  reason?: QuoteResponseReason;
  reasonText?: string;
  premiumAmount?: number;
  matchIndex?: number;
  matchDisqualifier?: boolean;
  expirationDate?: string;
  quoteResponseOptions?: QuoteResponseOption[];
  tags?: TagModel[];
  type?: QuoteResponseType;
  validThroughDate?: string;
  effectiveDate?: string;
  proposalDate?: string;
  proposalNo?: string;
  scenarioNo?: string;
  enrollmentTier?: RateType;
  specEnrollmentEE?: number;
  specEnrollmentES?: number;
  specEnrollmentEC?: number;
  specEnrollmentED?: number;
  specEnrollmentEF?: number;
  specEnrollmentCE?: number;
  aggEnrollmentEE?: number;
  aggEnrollmentES?: number;
  aggEnrollmentEC?: number;
  aggEnrollmentED?: number;
  aggEnrollmentEF?: number;
  aggEnrollmentCE?: number;
  incumbent?: boolean;
  laserConfirmation?: QuoteResponseLaserConfirmationType;
  constructor(status: QuoteResponseStatus = QuoteResponseStatus.NEW) {
    this.status = status;
    this.type = QuoteResponseType.ILLUSTRATIVE;
  }
}

export class QuoteResponseMinModel {
  id?: number;
  status?: QuoteResponseStatus;
  quoteRequest?: QuoteRequestModel;
  org?: OrgMinModel;
  createdDate?: string;
  lastModifiedDate?: string;
  drcCount?: number;
  stateChangeDate?: string;
  orgName?: string;
  matchIndex?: number;
  matchDisqualifier?: boolean;
  validThroughDate?: string;
  reason?: QuoteResponseReason;
  reasonText?: string;
  incumbent?: boolean;
  firstSubmitDate?: string;
  laserConfirmation?: QuoteResponseLaserConfirmationType;
}

export class QuoteResponseOptionStatusChange {
  quoteResponseOptionId?;
  quoteResponseId?;
  status: QuoteResponseStatus;
}

export const quoteResponseStatusDef: DataDefModel = new DataDefModel({
  label: 'Stage',
  key: 'status',
  type: DATA_TYPES.enum,
  options: quoteResponseStatusOptions
});

export function determineEnrollmentTier(response: QuoteResponseModel, ocrData: OcrModel[]) {
  const composite = nullOrUndefinedToZero(response.specEnrollmentCE);
  const empOnly = nullOrUndefinedToZero(response.specEnrollmentEE);
  const empSpouse = nullOrUndefinedToZero(response.specEnrollmentES);
  const empChild = nullOrUndefinedToZero(response.specEnrollmentEC);
  const empDependant = nullOrUndefinedToZero(response.specEnrollmentED);
  const family = nullOrUndefinedToZero(response.specEnrollmentEF);

  if (
    (composite > 0 && empOnly === 0 && empSpouse === 0 && empChild === 0 && empDependant === 0 && family === 0) ||
    (empOnly > 0 && empSpouse === 0 && empChild === 0 && empDependant === 0 && family === 0)
  ) {
    response.enrollmentTier = RateType.TS01;
    // if EE is populated then use that over any value sent in CE
    if (empOnly !== 0) {
      response.specEnrollmentCE = response.specEnrollmentEE;
    }
    response.specEnrollmentEE = null;
    response.specEnrollmentES = null;
    response.specEnrollmentEC = null;
    response.specEnrollmentED = null;
    response.specEnrollmentEF = null;
  } else if (empOnly > 0 && empSpouse === 0 && empChild === 0 && empDependant === 0 && family > 0) {
    response.enrollmentTier = RateType.TS02;
    response.specEnrollmentCE = null;
    response.specEnrollmentES = null;
    response.specEnrollmentEC = null;
    response.specEnrollmentED = null;
  } else if (empOnly > 0 && empSpouse === 0 && empChild === 0 && empDependant > 0 && family > 0) {
    response.enrollmentTier = RateType.TS03;
    response.specEnrollmentCE = null;
    response.specEnrollmentES = null;
    response.specEnrollmentEC = null;
  } else if (empOnly > 0 && empSpouse > 0 && empChild > 0 && empDependant === 0 && family > 0) {
    response.enrollmentTier = RateType.TS04;
    response.specEnrollmentCE = null;
    response.specEnrollmentED = null;
  }

  // Update the ocr fields, they will override what is set above if they don't get set here
  if (ocrData) {
    const keysToUpdate = [
      'specEnrollmentCE',
      'specEnrollmentEE',
      'specEnrollmentES',
      'specEnrollmentEC',
      'specEnrollmentED',
      'specEnrollmentEF'
    ];
    ocrData?.forEach((ocrModel) =>
      ocrModel.fields.forEach(
        (ocrField) =>
          (ocrField.value = keysToUpdate.includes(ocrField.key) ? get(response, ocrField.key) : ocrField.value)
      )
    );
  }
}

/**
 * Defines a comparison for ordering options when comparing options based on a number of criteria and criteria combinations.
 */
export class ProposalOptionComparator {
  constructor(private currentSpecDeductible: number) {}

  private compareNumberUndefinedOrNullLast(aNumber, bNumber) {
    return (aNumber === undefined || aNumber === null) && (bNumber === undefined || bNumber === null)
      ? 0
      : (aNumber === undefined || aNumber === null) && !(bNumber === undefined || bNumber === null)
        ? 1
        : !(aNumber === undefined || aNumber === null) && (bNumber === undefined || bNumber === null)
          ? -1
          : aNumber - bNumber;
  }

  /**
   * Compares two objects that have aspects of both QuoteResponseModels and QuoteResponseOptions
   *
   * @returns
   * -1 if a comes before b
   * 0 if a is equal to b
   * 1 if a comes after b
   */
  public compare(a, b) {
    const hasCurrentSpecDeductible = !!this.currentSpecDeductible;
    const aPremiumAmount = a.premiumAmount === 0 ? Number.MAX_VALUE : a.premiumAmount;
    const bPremiumAmount = b.premiumAmount === 0 ? Number.MAX_VALUE : b.premiumAmount;
    // Each of these is a comparison defined as a number in order of precedence
    const compareIncumbentMatchingCurrentSpecDeductible =
      Number(b.incumbent && hasCurrentSpecDeductible && b.specDeductible === this.currentSpecDeductible) -
      Number(a.incumbent && hasCurrentSpecDeductible && a.specDeductible === this.currentSpecDeductible);
    const compareNonIncumbentMatchingCurrentSpecDeductible =
      Number(hasCurrentSpecDeductible && b.specDeductible === this.currentSpecDeductible) -
      Number(hasCurrentSpecDeductible && a.specDeductible === this.currentSpecDeductible);
    const compareSpecDeductible = this.compareNumberUndefinedOrNullLast(a.specDeductible, b.specDeductible);
    const comparePremiumAmount = this.compareNumberUndefinedOrNullLast(aPremiumAmount, bPremiumAmount);
    const compareCarrierName = a.carrierName.localeCompare(b.carrierName);
    const compareOrderIndex = a.orderIndex - b.orderIndex;
    return compareIncumbentMatchingCurrentSpecDeductible !== 0
      ? compareIncumbentMatchingCurrentSpecDeductible
      : compareNonIncumbentMatchingCurrentSpecDeductible !== 0
        ? compareNonIncumbentMatchingCurrentSpecDeductible
        : compareSpecDeductible !== 0
          ? compareSpecDeductible
          : comparePremiumAmount !== 0
            ? comparePremiumAmount
            : compareCarrierName !== 0
              ? compareCarrierName
              : compareOrderIndex;
  }
}
