import { AbstractControl, AsyncValidatorFn, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CustomAsyncValidatorFn } from '@form-lib/models/validators.model';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export class FieldValidators {
  static eitherOr(otherFieldKey: string, otherFieldLabel?: string): ValidatorFn {
    let endTheLoop;
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!control.parent) {
        return null;
      }

      const otherControl = control.parent.controls[otherFieldKey];
      if (!otherControl) return null;

      if (!endTheLoop) {
        endTheLoop = true;
        otherControl.updateValueAndValidity();
        otherControl.markAsTouched();
      }
      endTheLoop = false;
      const otherControlValue = otherControl.value;

      if (
        value !== null &&
        value !== undefined &&
        value !== '' &&
        otherControlValue !== null &&
        otherControlValue !== undefined &&
        otherControlValue !== ''
      ) {
        return { eitherOr: `Only one of either this field or '${otherFieldLabel}' should have a value.` };
      }
      return null;
    };
  }

  static onlyOneValueOf(otherFieldKey: string): ValidatorFn {
    let endTheLoop;
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!control.parent) {
        return null;
      }
      const otherControl = control.parent.get(otherFieldKey);
      if (!endTheLoop) {
        endTheLoop = true;
        otherControl.updateValueAndValidity();
      }
      endTheLoop = false;
      const otherControlValue = otherControl.value;
      let errorObj;
      if (
        value !== null &&
        value !== undefined &&
        value !== '' &&
        otherControlValue !== null &&
        otherControlValue !== undefined &&
        otherControlValue !== ''
      ) {
        errorObj = { onlyOneValueOf: `Only one of either field is needed` };
      } else if (
        (value === null || value === undefined || value === '') &&
        (otherControlValue === null || otherControlValue === undefined || otherControlValue === '')
      ) {
        errorObj = { eitherOr: `A value in either field is required` };
      }
      // Shows error message on other control if this control is being used and the the other has
      // not been interacted with yet.
      if (control.touched || (control.dirty && otherControl.untouched)) otherControl.markAsTouched();
      return errorObj;
    };
  }

  static urlValidator(control: UntypedFormControl): ValidationErrors {
    const regex = new RegExp(
      '^((([A-Za-z]{3,9}:(?:\\/\\/)?)(?:[-;:&=\\+\\$,\\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\\+\\$,\\w]+@)[A-Za-z0-9.-]+)((?:\\/' +
        '[\\+~%\\/.\\w-_]*)?\\??(?:[-\\+=&;%@.\\w_]*)#?(?:[\\w]*))?)$'
    );
    if (control.value) {
      return regex.test(control.value) ? null : { url: true };
    }
    return null;
  }

  static rangeValidator(minValue: number, maxValue: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const { min, max } = value;

      if (min > maxValue || max > maxValue) {
        return { rangeError: `Please enter values less than ${maxValue}.` };
      }
      if (min < minValue || max < minValue) {
        return { rangeError: `Please enter values greater than ${minValue}.` };
      }
      if (!isNaN(min) && !isNaN(max) && min > max) {
        return { rangeError: `Min value cannot be greater than max.` };
      }

      return null;
    };
  }

  static mustBeLessThanOrEqualTo(key: string, label: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }

      const value = control.value;
      const targetValue = control.parent.get(key).value;
      if (!value) {
        return null;
      }

      if (!isNaN(value) && !isNaN(targetValue) && value > targetValue) {
        return { mustBeLessThanOrEqualTo: `Value must be less than or equal to ${label}.` };
      }

      return null;
    };
  }

  static noDuplicates(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value || !value.length) {
      return null;
    }
    const sortedArr = value.slice().sort();
    for (let i = 0; i < sortedArr.length - 1; i++) {
      if (sortedArr[i + 1] === sortedArr[i]) {
        return { rangeError: `Values must be unique.` };
      }
    }
    return null;
  }

  static usPhoneValidator(control: UntypedFormControl): ValidationErrors {
    const regex = new RegExp('^(\\([0-9]{3}\\)\\s?|[0-9]{3}-)[0-9]{3}-[0-9]{4}$');
    if (control.value) {
      return regex.test(control.value) ? null : { phone: true };
    }
    return null;
  }

  static usZip(control: UntypedFormControl): ValidationErrors {
    const regex = new RegExp('^\\d{5}(?:[-\\s]\\d{4})?$');
    if (control.value) {
      return regex.test(control.value) ? null : { zip: true };
    }
    return null;
  }

  static usFedId(control: UntypedFormControl): ValidationErrors {
    const regex = new RegExp('^\\d{2}-\\d{7}$');
    if (control.value) {
      return regex.test(control.value) ? null : { fedId: true };
    }
    return null;
  }

  static mustBeInteger(control: UntypedFormControl): ValidationErrors {
    if (control.value) {
      return Number.isInteger(control.value) ? null : { mustBeInteger: true };
    }
    return null;
  }

  static customValidator(validationFn: (_?) => boolean, errorMessage?: string) {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const error = !validationFn(control);
      return error ? { customValidation: errorMessage } : null;
    };
  }

  static maxSize(maxSize: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value || !value.length) {
        return null;
      }
      if (value.length > maxSize) {
        return { maxSizeError: `Max ${maxSize} values allowed.` };
      }
      return null;
    };
  }

  static minSize(minSize: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value || !value.length) {
        return null;
      }
      if (value.length < minSize) {
        return { maxSizeError: `Min ${minSize} values allowed.` };
      }
      return null;
    };
  }
}

export class AsyncFieldValidators {
  static customValidator(validationFn: CustomAsyncValidatorFn, errorMessage?: string): AsyncValidatorFn {
    // to help with memoization, save both the last value and the last error.  The reason both are necessary is
    //  because if the field is found to fail validation, but a prior validator (say, length) keeps this from getting
    //  called again, it may look like the validation passes if null is returned.
    //  if this is a server call for data that may shift (i.e. exists api call for duplication name checks), there is
    //  a small chance of another user choosing the same value, but this validator not checking it because it is being
    //  cached on the client.  But this is the same issue with other race conditions and the api will catch it.
    let controlValue;
    let lastErrorExists: boolean = false;
    return (control: UntypedFormControl): Observable<ValidationErrors | null> => {
      if (controlValue && controlValue === control.value) {
        // value didn't change, to the validation results stand
        return of(lastErrorExists ? { customValidation: errorMessage } : null);
      }

      controlValue = control.value;
      return timer(300).pipe(
        switchMap((_) => validationFn(controlValue, control)),
        tap((errorExists) => (lastErrorExists = errorExists)),
        map((_) => (lastErrorExists ? { customValidation: errorMessage } : null))
      );
    };
  }
}
