import { NgModule } from '@angular/core';
import { OrgLogoComponent } from '@common/components/org-logo/org-logo.component';
import { AsyncPipe } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [OrgLogoComponent],
  imports: [AsyncPipe, FontAwesomeModule],
  exports: [OrgLogoComponent]
})
export class OrgLogoModule {}
