import { AfterViewInit, Component } from '@angular/core';
import { BaseFieldComponent } from '../base-field/base-field.component';

/*
 * IMPORTANT: this component is available in the DataDefModel if you provide def.layout.toggle=true
 * useful when the datatype is checkbox or boolean to instead show the toggle slider
 * and also as a standalone component.
 * */
@Component({
  selector: 'app-toggle-field',
  template: `
    <div class="toggle-container">
      <mat-slide-toggle
        style="opacity: 1"
        color="primary"
        [formControl]="control"
        [checked]="showChecked"
        (ngModelChange)="modelChanged($event)"
        (change)="_valueChanged($event.checked)"
      >
        <span truncateText [apply]="truncate">
          {{
            toggleOptions
              ? control.value === null || control.value === false || control.value === toggleOptions[0].value
                ? toggleOptions[0].label
                : toggleOptions[1].label
              : label
          }}
        </span>
        <mat-error>
          <app-error-renderer [errors]="control.errors"></app-error-renderer>
        </mat-error>
      </mat-slide-toggle>
    </div>
  `,
  styleUrls: ['../../form-lib.scss'],
  styles: [
    `
      .toggle-container {
        padding: 0.25rem 0;
      }
    `
  ]
})
export class ToggleFieldComponent extends BaseFieldComponent implements AfterViewInit {
  showChecked: boolean;
  previousValue: any;

  ngAfterViewInit() {
    if (this.toggleOptions) {
      setTimeout(() => {
        this.showChecked = this.control.value === this.toggleOptions[1].value;
      });
    }
  }

  modelChanged(event) {
    // when the value changes because a user clicks the slider, it moves back to true/false
    // if toggle options are present then needed to translate that back into the proper option values

    // short circuits the setValue call, as it'll come back through here and cause infinite badness
    if (this.toggleOptions && typeof event !== 'boolean' && this.previousValue && this.previousValue === event) {
      return;
    }

    if (this.toggleOptions) {
      if (typeof event === 'boolean') {
        const v = event ? this.toggleOptions[1].value : this.toggleOptions[0].value;
        this.previousValue = v;
        this.showChecked = event;
        this.control.setValue(v);
      } else {
        this.showChecked = event === this.toggleOptions[1].value;
        this.previousValue = event;
      }
    } else {
      // if previous value is undefined (the field could be undefined in the object which is the 'false' state)
      // only call value changed if moving to true, otherwise it's ok to check previous value !== event to call value changed
      // for undefined -> false, true -> false, false -> true
      if ((this.previousValue === undefined && event) || this.previousValue !== event) {
        this._valueChanged(event);
      }
      this.previousValue = event;
    }
  }
}
