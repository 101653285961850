<label [class]="{ 'inline-label': inline, required: required }">
  <span
    class="label-text"
    [class]="!control.disabled && dataDef?.labelClasses ? dataDef.labelClasses : ''"
    truncateText
    [apply]="truncate"
    *ngIf="inline"
  >
    @if (dataDef.icon) {
      <fa-icon class="pr-1" [icon]="dataDef.icon"></fa-icon>
    }
    {{ label }}</span
  >
  <mat-form-field [appearance]="appearance" floatLabel="always">
    <mat-label *ngIf="!(inline || inlineNoLabel)" class="large-label"> {{ label }} </mat-label>
    <mat-chip-grid [formControl]="control" [required]="required" #chipList>
      <mat-chip-row
        *ngFor="let obj of control.value"
        [removable]="control.enabled"
        (removed)="remove(obj)"
        [disabled]="control.disabled"
      >
        {{ obj.label ? obj.label : obj }}
        <mat-icon matChipRemove *ngIf="control.enabled">cancel</mat-icon>
      </mat-chip-row>
      <input
        type="number"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event, false)"
        [disabled]="control.disabled"
      />
    </mat-chip-grid>
    <mat-hint>
      <div *ngIf="!control.parent?.disabled || !control.disabled">{{ hint ? hint : hintText }}</div>
    </mat-hint>
    <mat-error>
      <app-error-renderer [errors]="control.errors"></app-error-renderer>
    </mat-error>
  </mat-form-field>
</label>
