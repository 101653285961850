import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { FormLibModule } from '@form-lib/form-lib.module';
import { RfiResponseComponent } from '@common/components/rfi-response/rfi-response.component';
import { FormBuilderModule } from '@common/components/form-builder/form-builder.module';
import { AccordionLibModule } from '@accordion-lib/accordion-lib.module';

@NgModule({
  imports: [SharedModule, FormLibModule, FormBuilderModule, AccordionLibModule],
  exports: [RfiResponseComponent],
  declarations: [RfiResponseComponent]
})
export class RfiResponseModule {}
