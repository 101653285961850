<app-dialog-close icon mat-dialog-close></app-dialog-close>
<app-dialog-label> {{ data.requestedOrg?.name }} Profile </app-dialog-label>
<!-- rfiObservable[0] is the response, rfiObservable[1] is the template -->
@if (rfiObservable$ | async; as rfiObservable) {
  <div
    class="primary-color-bar"
    [style.background-color]="!!rfiObservable[0].primaryColor ? '#' + rfiObservable[0].primaryColor : '#574f77'"
  ></div>
  <div
    class="secondary-color-bar"
    [style.background-color]="
      !!rfiObservable[0].secondaryColor
        ? '#' + rfiObservable[0].secondaryColor
        : !!rfiObservable[0].primaryColor
          ? rfiObservable[0].primaryColor
          : '#817b99'
    "
  ></div>
  <app-dialog-content>
    <app-org-logo
      class="org-logo"
      [org]="{
        id: rfiObservable[0].orgId,
        imageRmtFile: rfiObservable[0].imageRmtFile
      }"
    ></app-org-logo>
    <app-accordion [open]="openAccordions" class="company-overview-accordion">
      <app-accordion-item>
        <div class="accordion-item-header company-overview-heading">Company Overview</div>
        <div class="accordion-item-content company-overview bg-mid-gray">
          <div class="company-overview-label">Name</div>
          <div class="company-overview-value">{{ emptyDefault(rfiObservable[0].orgName) }}</div>
          <div class="company-overview-label">Service</div>
          <div class="company-overview-value">{{ emptyDefault(rfiObservable[0].services) }}</div>
          <div class="company-overview-label">Specialties</div>
          <div class="company-overview-value">{{ emptyDefault(rfiObservable[0].specialties) }}</div>
          <div class="company-overview-label">Headquarters</div>
          <div class="company-overview-value">{{ emptyDefault(rfiObservable[0].headquarters) }}</div>
          <div class="company-overview-label">Company size</div>
          <div class="company-overview-value">{{ emptyDefault(rfiObservable[0].companySize) }}</div>
          <div class="company-overview-label">Founded</div>
          <div class="company-overview-value">{{ emptyDefault(rfiObservable[0].founded) }}</div>
          <div class="company-overview-label">Ownership Structure</div>
          <div class="company-overview-value">{{ emptyDefault(rfiObservable[0].ownershipStructure) }}</div>
          <div class="company-overview-label">
            <fa-icon
              class="pr-1"
              [style.color]="!!rfiObservable[0].primaryColor ? '#' + rfiObservable[0].primaryColor : '#574f77'"
              [icon]="faGlobeAmericas"
            ></fa-icon>
            Website
          </div>
          <div class="company-overview-value">{{ emptyDefault(rfiObservable[0].website) }}</div>
          <div class="company-overview-label">
            <fa-icon
              class="pr-1"
              [style.color]="!!rfiObservable[0].primaryColor ? '#' + rfiObservable[0].primaryColor : '#574f77'"
              [icon]="faLinkedin"
            ></fa-icon>
            LinkedIn
          </div>
          <div class="company-overview-value">{{ emptyDefault(rfiObservable[0].linkedin) }}</div>
          <div class="company-overview-label">
            <fa-icon
              class="pr-1"
              [style.color]="!!rfiObservable[0].primaryColor ? '#' + rfiObservable[0].primaryColor : '#574f77'"
              [icon]="faFacebookSquare"
            ></fa-icon>
            Facebook
          </div>
          <div class="company-overview-value">{{ emptyDefault(rfiObservable[0].facebook) }}</div>
          <div class="company-overview-label">
            <fa-icon
              class="pr-1"
              [style.color]="!!rfiObservable[0].primaryColor ? '#' + rfiObservable[0].primaryColor : '#574f77'"
              [icon]="faTwitterSquare"
            ></fa-icon>
            X (Formerly Twitter)
          </div>
          <div class="company-overview-value">{{ emptyDefault(rfiObservable[0].twitter) }}</div>
          <div class="company-overview-label">
            <fa-icon
              class="pr-1"
              [style.color]="!!rfiObservable[0].primaryColor ? '#' + rfiObservable[0].primaryColor : '#574f77'"
              [icon]="faInstagram"
            ></fa-icon>
            Instagram
          </div>
          <div class="company-overview-value">{{ emptyDefault(rfiObservable[0].instagram) }}</div>
        </div>
      </app-accordion-item>
    </app-accordion>
    <app-rfi-response
      [readOnly]="data.readOnly"
      [rfiFormFields]="rfiObservable[1].rfiFields"
      [rfiFieldData]="rfiObservable[0].rfiFieldData"
      [openAccordions]="openAccordions"
      (update)="formUpdate($event)"
    ></app-rfi-response>
  </app-dialog-content>
} @else {
  <app-in-progress-indicator class="rfi-spinner" diameter="5rem" indicatorStyle="thick"></app-in-progress-indicator>
}
<app-dialog-actions>
  @if (!data.readOnly) {
    <button mat-raised-button [color]="buttonColor" (click)="submit()">Save</button>
  }
  <button mat-raised-button mat-dialog-close>{{ data.readOnly ? 'Close' : 'Cancel' }}</button>
</app-dialog-actions>
