import { NgModule } from '@angular/core';
import { WorkspaceLibModule } from '@app/libraries/workspace-lib/workspace-lib.module';

import { AlertDialogComponent } from '@common/dialogs/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from '@common/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DialogsService } from '@common/dialogs/dialogs.service';
import { EditSupportFileDialogComponent } from '@common/dialogs/edit-support-file-dialog/edit-support-file-dialog.component';
import { ImportHelpDialogComponent } from '@common/dialogs/import-help-dialog/import-help-dialog.component';
import { InputDialogComponent } from '@common/dialogs/input-dialog/input-dialog.component';
import { MessageDialogComponent } from '@common/dialogs/message-dialog/message-dialog.component';
import { MonospaceMessageDialogComponent } from '@common/dialogs/monospace-message-dialog/monospace-message-dialog.component';
import { ProposalConfirmationDialogComponent } from '@common/dialogs/proposal-confirmation-dialog/proposal-confirmation-dialog.component';
import { WorkspaceConfirmationDialogComponent } from '@common/dialogs/workspace-confirmation-dialog/workspace-confirmation-dialog.component';
import { FileUploadLibModule } from '@file-upload-lib/file-upload-lib.module';
import { FormLibModule } from '@form-lib/form-lib.module';
import { InfoLibModule } from '@info-lib/info-lib.module';
import { SharedModule } from '@shared/shared.module';
import { QuoteResponseMessageDialogComponent } from '@common/dialogs/quote-response-meessage-dialog/quote-response-message-dialog.component';
import { FileUploadModule } from 'ng2-file-upload';
import { DataTableLibModule } from '@data-table-lib/data-table-lib.module';
import { QuoteSupportingFileDialogComponent } from '@common/dialogs/quote-supporting-file-dialog/quote-supporting-file-dialog.component';
import { MultiFileUploadModule } from '@common/components/multi-file-upload/multi-file-upload.module';
import { QuoteRequestManifestDialogComponent } from '@common/dialogs/manifest-dialogs/quote-request-manifest-dialog.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { QuoteResponseManifestDialogComponent } from '@common/dialogs/manifest-dialogs/quote-response-manifest-dialog.component';
import { QuoteResponseRxManifestDialogComponent } from '@common/dialogs/manifest-dialogs/quote-response-rx-manifest-dialog.component';
import { QuoteSupportingFilePdfDialogComponent } from '@common/dialogs/quote-supporting-file-pdf-dialog/quote-supporting-file-pdf-dialog.component';
import { RfiResponseDialogComponent } from '@common/dialogs/rfi-response-dialog/rfi-response-dialog.component';
import { RfiResponseModule } from '@common/components/rfi-response/rfi-response.module';
import { AccordionLibModule } from '@accordion-lib/accordion-lib.module';
import { OrgLogoModule } from '@common/components/org-logo/org-logo.module';

const moduleExports = [FormLibModule, SharedModule];

const exportItems = [];

const entryComponents = [
  ConfirmationDialogComponent,
  InputDialogComponent,
  MonospaceMessageDialogComponent,
  MessageDialogComponent,
  AlertDialogComponent,
  EditSupportFileDialogComponent,
  ImportHelpDialogComponent,
  ProposalConfirmationDialogComponent,
  WorkspaceConfirmationDialogComponent,
  QuoteResponseMessageDialogComponent,
  QuoteSupportingFileDialogComponent,
  QuoteSupportingFilePdfDialogComponent,
  QuoteRequestManifestDialogComponent,
  QuoteResponseManifestDialogComponent,
  QuoteResponseRxManifestDialogComponent,
  RfiResponseDialogComponent
];

@NgModule({
  imports: [
    ...moduleExports,
    FileUploadLibModule,
    WorkspaceLibModule,
    InfoLibModule,
    FileUploadModule,
    DataTableLibModule,
    MultiFileUploadModule,
    PdfJsViewerModule,
    RfiResponseModule,
    AccordionLibModule,
    OrgLogoModule
  ],
  declarations: [...entryComponents],
  exports: [...exportItems],
  providers: [DialogsService]
})
export class DialogsModule {}
