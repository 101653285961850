import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { enterLeaveAnimation, openCloseAnimation } from '../animation';

let timeoutRef;
const duration = 250;

@Component({
  selector: 'app-accordion-item',
  exportAs: 'accordionItem',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  animations: [openCloseAnimation, enterLeaveAnimation]
})
export class AccordionItemComponent {
  @Input() incumbent = false;
  @Input('isOpen') isOpen = false;
  @Input() disabled = false; // Disables toggle
  @Input() minHeight;

  @Output() toggle = new EventEmitter<boolean>();
  @HostBinding('@enterLeaveAnimation') enterLeaveAnimation = '';

  chevronIcon = faChevronUp;
  isClosed = true;

  public onClickHeader() {
    this.isOpen = !this.isOpen;
    this.toggle.emit(this.isOpen);
    if (timeoutRef) clearTimeout(timeoutRef);
    if (!this.isOpen) {
      timeoutRef = setTimeout(() => {
        this.isClosed = true;
        timeoutRef = null;
      }, duration);
    } else {
      this.isClosed = false;
      timeoutRef = null;
    }
  }
}
