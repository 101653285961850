import { AccordionItemComponent } from '@accordion-lib/accordion-item/accordion-item.component';
import { Component, ContentChildren, Input, OnChanges, QueryList, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-accordion',
  template: `
    <div class="accordion">
      <ng-content></ng-content>
    </div>
  `
})
export class AccordionComponent implements OnChanges {
  @Input() close: any;
  @Input() open: any;

  @ContentChildren(AccordionItemComponent) items: QueryList<AccordionItemComponent>;

  ngOnChanges({ close, open }: SimpleChanges): void {
    if (close && this.items?.length) {
      this.items.filter((item) => item.isOpen).forEach((item) => item.onClickHeader());
    }

    if (open && this.items?.length) {
      this.items.filter((item) => !item.isOpen).forEach((item) => item.onClickHeader());
    }
  }
}
