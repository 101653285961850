import { Component, Input, OnInit } from '@angular/core';
import { ValidatorsMap } from '@form-lib/models/validators.model';
import { getDateFromString, isValidDate } from '@app/tools/date';
import { BaseFieldComponent } from '../base-field/base-field.component';

@Component({
  selector: 'app-date-field',
  template: `
    <label [class]="{ 'inline-label': inline, required: required }">
      <span
        class="label-text"
        [class]="!control.disabled && dataDef?.labelClasses ? dataDef.labelClasses : ''"
        truncateText
        [apply]="truncate"
        *ngIf="inline"
      >
        {{ label }}</span
      >
      <mat-form-field [appearance]="appearance" [floatLabel]="inline ? 'never' : 'always'">
        <mat-label *ngIf="!(inline || inlineNoLabel)" class="large-label"> {{ label }} </mat-label>
        <input
          matInput
          #input
          (input)="_valueChanged(input.value)"
          placeholder="&nbsp;&nbsp;mm/dd/yyyy"
          [formControl]="control"
          [required]="required"
          [matDatepicker]="picker"
          [readonly]="readOnly"
          [min]="minDate"
          [max]="maxDate"
          appDateFormatter
        />
        <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="!readOnly"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint>
          <div truncateText *ngIf="!control.parent?.disabled || !control.disabled">{{ hint }}</div>
        </mat-hint>
        <mat-error>
          <app-error-renderer [errors]="control.errors"></app-error-renderer>
        </mat-error>
      </mat-form-field>
    </label>
  `,
  styleUrls: ['../../form-lib.scss']
})
export class DateFieldComponent extends BaseFieldComponent implements OnInit {
  minDate: Date;
  maxDate: Date;

  ngOnInit() {
    super.ngOnInit();
    if ((this.dataDef.validators as ValidatorsMap)?.minDate || (this.dataDef.validators as ValidatorsMap)?.maxDate) {
      if (isValidDate((this.dataDef.validators as ValidatorsMap)?.minDate as Date)) {
        this.minDate = (this.dataDef.validators as ValidatorsMap)?.minDate as Date;
      } else {
        this.minDate = getDateFromString((this.dataDef.validators as ValidatorsMap)?.minDate as string);
      }
      if (isValidDate((this.dataDef.validators as ValidatorsMap)?.maxDate as Date)) {
        this.maxDate = (this.dataDef.validators as ValidatorsMap)?.maxDate as Date;
      } else {
        this.maxDate = getDateFromString((this.dataDef.validators as ValidatorsMap)?.maxDate as string);
      }
    }
    // put some basic rails on the date, 100 years in the past or future if not set previously
    if (!this.minDate) {
      this.minDate = new Date(new Date().getFullYear() - 100, 0, 1);
    }
    if (!this.maxDate) {
      this.maxDate = new Date(new Date().getFullYear() + 100, 11, 31);
    }
  }
}
