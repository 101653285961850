import { Component } from '@angular/core';
import { Memoize } from '@app/tools/decorators/memoize.decorator';
import { BaseMultiFieldComponent } from '../base-multi-field/base-multi-field.component';

@Component({
  selector: 'app-multi-input-field',
  template: `
    <label [class]="{ 'inline-label': inline, required: required }">
      <span
        class="label-text"
        [class]="!control.disabled && dataDef?.labelClasses ? dataDef.labelClasses : ''"
        truncateText
        [apply]="truncate"
        *ngIf="inline"
      >
        @if (dataDef.icon) {
          <fa-icon class="pr-1" [icon]="dataDef.icon"></fa-icon>
        }
        {{ label }}</span
      >
      <mat-form-field [appearance]="appearance" floatLabel="always">
        <mat-label *ngIf="!(inline || inlineNoLabel)" class="large-label"> {{ label }} </mat-label>
        <mat-chip-grid [formControl]="control" [required]="required" #chipGrid>
          @for (obj of getControlValueAsIterator(control.value); track obj) {
            <mat-chip-row (removed)="remove(obj)">
              {{ obj.label ? obj.label : obj }}
              @if (control.enabled) {
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              }
            </mat-chip-row>
          }
          <input
            [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event, false)"
          />
        </mat-chip-grid>
      </mat-form-field>
    </label>
  `,
  styleUrls: ['../../form-lib.scss']
})
export class MultiInputFieldComponent extends BaseMultiFieldComponent {
  @Memoize()
  getControlValueAsIterator(value: any): Array<any> {
    return value ? (value as []) : [];
  }

  addOnBlur = true;
}
