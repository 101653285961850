import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrgModel } from '@main/org/models/org.model';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { OrgHttpService } from '@main/org/org-http.service';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-org-logo',
  template: `
    @if (logoImage$ | async; as image) {
      <img [style.height]="imageSize" [src]="image" />
    } @else {
      <fa-icon [icon]="buildingIcon" class="color-light-gray" aria-hidden="true" size="2x"></fa-icon>
    }
  `
})
export class OrgLogoComponent implements OnInit, OnChanges {
  @Input() org: OrgModel;
  @Input() imageSize: string = '2.5rem';
  logoImage$: Observable<SafeUrl>;
  buildingIcon = faBuilding;

  constructor(
    private orgService: OrgHttpService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.setupOrgLogo();
  }

  ngOnChanges({ org }: SimpleChanges): void {
    if (org?.currentValue) {
      this.setupOrgLogo();
    }
  }

  setupOrgLogo() {
    if (this.org?.imageRmtFile?.id) {
      this.logoImage$ = this.orgService
        .getLogoImage(this.org?.id)
        .pipe(map((imgFile) => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(imgFile))));
    } else {
      this.logoImage$ = null;
    }
  }
}
