import { animate, group, query, stagger, state, style, transition, trigger } from '@angular/animations';

export const duration = 250;
const translateIn = 'translateY(0)';
const translateOut = 'translateY(-30%)';

export const enterLeaveAnimation = trigger('enterLeaveAnimation', [
  transition('void => *', [
    query('.accordion-item__header', style({ opacity: 0, height: '0', transform: translateOut })),
    query('.accordion-item__header', [
      stagger('5ms', animate(`${duration}ms`, style({ opacity: 1, height: '*', transform: translateIn })))
    ])
  ]),
  transition('* => void', [
    query('.accordion-item__header', style({ opacity: 1, height: '*', transform: translateIn })),
    query('.accordion-item__header, .accordion-item__content:not(.is-closed)', [
      animate(`${duration}ms`, style({ opacity: 0, height: 0, transform: translateOut }))
    ])
  ])
]);

export const openCloseAnimation = trigger('openCloseAnimation', [
  state('true', style({ height: '*' })),
  state('false', style({ height: 0 })),
  transition('true <=> false', group([animate(`${duration}ms`)]))
]);
