import { Component } from '@angular/core';
import { BaseFieldComponent } from '../base-field/base-field.component';

@Component({
  selector: 'app-number-field',
  template: `
    <label [class]="{ 'inline-label': inline, required: required }">
      <span
        class="label-text"
        [class]="!control.disabled && dataDef?.labelClasses ? dataDef.labelClasses : ''"
        truncateText
        [apply]="truncate"
        *ngIf="inline"
      >
        @if (dataDef.icon) {
          <fa-icon class="pr-1" [icon]="dataDef.icon"></fa-icon>
        }
        {{ label }}</span
      >
      <mat-form-field [appearance]="appearance" floatLabel="always">
        <mat-label *ngIf="!(inline || inlineNoLabel)" class="large-label"> {{ label }} </mat-label>
        <input
          #input
          (input)="_valueChanged(input.value)"
          matInput
          type="number"
          [formControl]="control"
          [required]="required"
          [readonly]="readOnly"
        />
        <mat-hint>
          <div truncateText *ngIf="!control.parent?.disabled || !control.disabled">{{ hint }}</div>
        </mat-hint>
        <mat-error>
          <app-error-renderer [errors]="control.errors"></app-error-renderer>
        </mat-error>
      </mat-form-field>
    </label>
  `,
  styleUrls: ['../../form-lib.scss']
})
export class NumberFieldComponent extends BaseFieldComponent {
  constructor() {
    super();
  }
}
