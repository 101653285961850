import { NgModule } from '@angular/core';
import { FormBuilderComponent } from '@common/components/form-builder/form-builder.component';
import { FormLibModule } from '@form-lib/form-lib.module';
import { SharedModule } from '@shared/shared.module';
import { LibResourceModule } from '@lib-resource/lib-resource.module';

@NgModule({
  imports: [FormLibModule, SharedModule, LibResourceModule],
  declarations: [FormBuilderComponent],
  exports: [FormBuilderComponent],
  providers: []
})
export class FormBuilderModule {}
