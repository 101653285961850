<div class="mt-2 collapsible-form dense">
  <app-form
    #rfiResponseForm="appForm"
    id="rfiResponseForm"
    *ngIf="localData"
    [definitions]="dataDefs"
    [data]="localData"
    [editing]="!readOnly"
    [alwaysEditing]="!readOnly"
    (valueChanges)="formChanges($event)"
  >
    <div *ngIf="rfiResponseForm.form">
      <div class="rfi-response-form">
        <app-accordion [open]="openAccordions">
          @for (accordionSection of getAccordionSections(); track accordionSection) {
            <app-accordion-item>
              <div class="accordion-item-header bg-white">
                @if (
                  accordionSection.sectionControl?.formDef && showSectionHeader(accordionSection.sectionControl.formDef)
                ) {
                  <div class="section-label-text mt-2 mb-1">{{ accordionSection.sectionControl.formDef.name }}</div>
                }
              </div>
              <div class="accordion-item-content rfi-section-content">
                @for (child of accordionSection.childControls; track child) {
                  <div class="rfi-response-toggle-row">
                    @if (showToggle(child.formDef)) {
                      <app-toggle-field
                        class="rfi-response-toggle bg-extra-light-gray"
                        [control]="child.control.controls['value']"
                        [dataDef]="buildFieldDefinition(child.control, child.formDef)"
                        [inline]="true"
                        [readOnly]="readOnly"
                        [truncate]="false"
                        [ngClass]="{ 'field-disable': readOnly }"
                      ></app-toggle-field>
                    }
                    @if (showText(child.formDef)) {
                      <app-text-field
                        class="rfi-response"
                        [control]="child.control.controls['value']"
                        [dataDef]="buildFieldDefinition(child.control, child.formDef)"
                        [inline]="true"
                        [readOnly]="readOnly"
                        [truncate]="false"
                        [ngClass]="{ 'field-disable': readOnly }"
                      ></app-text-field>
                    }
                    @if (showTextArea(child.formDef)) {
                      <app-textarea-field
                        class="rfi-response"
                        [control]="child.control.controls['value']"
                        [dataDef]="buildFieldDefinition(child.control, child.formDef)"
                        [inline]="true"
                        [readOnly]="readOnly"
                        [truncate]="false"
                        [ngClass]="{ 'field-disable': readOnly }"
                      ></app-textarea-field>
                    }
                    @if (showSingleCurrency(child.formDef)) {
                      <app-currency-field
                        class="rfi-response"
                        [control]="child.control.controls['value']"
                        [dataDef]="buildFieldDefinition(child.control, child.formDef)"
                        [inline]="true"
                        [readOnly]="readOnly"
                        [truncate]="false"
                        [ngClass]="{ 'field-disable': readOnly }"
                      ></app-currency-field>
                    }
                    @if (showMultiCurrency(child.formDef)) {
                      <app-multi-currency-field
                        class="rfi-response"
                        [control]="child.control.controls['value']"
                        [dataDef]="buildFieldDefinition(child.control, child.formDef)"
                        [inline]="true"
                        [readOnly]="readOnly"
                        [truncate]="false"
                        [ngClass]="{ 'field-disable': readOnly }"
                      ></app-multi-currency-field>
                    }
                    @if (showSingleNumber(child.formDef)) {
                      <app-number-field
                        class="rfi-response"
                        [control]="child.control.controls['value']"
                        [dataDef]="buildFieldDefinition(child.control, child.formDef)"
                        [inline]="true"
                        [readOnly]="readOnly"
                        [truncate]="false"
                        [ngClass]="{ 'field-disable': readOnly }"
                      ></app-number-field>
                    }
                    @if (showMultiNumber(child.formDef)) {
                      <app-multi-number-field
                        class="rfi-response"
                        [control]="child.control.controls['value']"
                        [dataDef]="buildFieldDefinition(child.control, child.formDef)"
                        [inline]="true"
                        [readOnly]="readOnly"
                        [truncate]="false"
                        [ngClass]="{ 'field-disable': readOnly }"
                      ></app-multi-number-field>
                    }
                    @if (showSinglePercent(child.formDef)) {
                      <app-number-field
                        class="rfi-response"
                        [control]="child.control.controls['value']"
                        [dataDef]="buildFieldDefinition(child.control, child.formDef)"
                        [inline]="true"
                        [readOnly]="readOnly"
                        [truncate]="false"
                        [ngClass]="{ 'field-disable': readOnly }"
                      ></app-number-field>
                    }
                    @if (showMultiPercent(child.formDef)) {
                      <app-multi-number-field
                        class="rfi-response"
                        [control]="child.control.controls['value']"
                        [dataDef]="buildFieldDefinition(child.control, child.formDef)"
                        [inline]="true"
                        [readOnly]="readOnly"
                        [truncate]="false"
                        [ngClass]="{ 'field-disable': readOnly }"
                      ></app-multi-number-field>
                    }
                    @if (showSingleSelect(child.formDef)) {
                      <app-select-field
                        class="rfi-response"
                        [control]="child.control.controls['value']"
                        [dataDef]="buildFieldDefinition(child.control, child.formDef)"
                        [inline]="true"
                        [readOnly]="readOnly"
                        [truncate]="false"
                        [ngClass]="{ 'field-disable': readOnly }"
                      ></app-select-field>
                    }
                    @if (showMultiSelect(child.formDef)) {
                      <app-multi-select-field
                        class="rfi-response"
                        [control]="child.control.controls['value']"
                        [dataDef]="buildFieldDefinition(child.control, child.formDef)"
                        [inline]="true"
                        [readOnly]="readOnly"
                        [truncate]="false"
                        [ngClass]="{ 'field-disable': readOnly }"
                      ></app-multi-select-field>
                    }
                  </div>
                }
              </div>
            </app-accordion-item>
          }
        </app-accordion>
      </div>
    </div>
  </app-form>
</div>
